.filter-block {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 10px 0;
  margin-top: 20px;
}

.filter-card {
  flex: 0 0 auto;
  width: 285px;
  cursor: pointer;
  padding: 0 10px;
}

.filter-card > div {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  transition: all 0.2s ease;
  height: 100%;
}

.filter-card:hover > div {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.feature-title {
  color: #666;
  font-size: 14px;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature-title i {
  font-size: 12px;
  opacity: 0.6;
  margin-left: 8px;
  color: #358d30;
}

.filter-card > .active .feature-title i {
  opacity: 1;
}

.feature-flag-number {
  color: #333;
  font-size: 24px;
  font-weight: 600;
}

.filter-card > .active {
  border-color: #358d30 !important;
  background-color: rgba(73, 138, 68, 0.1) !important;
}

.filter-card > div:active {
  transform: scale(0.98);
}

@media (max-width: 768px) {
  .filter-card {
    width: 160px;
  }

  .feature-title {
    font-size: 13px;
  }

  .feature-flag-number {
    font-size: 20px;
  }

  .feature-title i {
    font-size: 11px;
  }
}

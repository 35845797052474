.principal-button {
  .fa-star {
    filter: invert(12%) sepia(200%) saturate(1400%) hue-rotate(45deg)
      brightness(150%) contrast(200%);
  }

  .fa-star:hover {
    filter: initial;
  }

  &:hover {
    .fa-star {
      filter: initial;
    }
  }
}

.title-section {
  border-left: 3px solid #74b959;
}

.modulo-items > .card {
  height: 132px;
  width: 17rem !important;
  margin: 15px;
  border: 1px solid #358d30;
}

.modulo-items > .card svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #74b84852;
}

.modulo-items > .card:hover {
  color: white;
  background-color: #74b959;
  fill: white;
  border-color: #74b959;
}

.modulo-items > .card:hover svg {
  fill: #ffffff1f;
}

.modulo-items > .card:hover svg * {
  fill: #ffffff1f;
}

.modulo-items > .card:hover i {
  fill: white;
  color: white;
  visibility: visible;
}

.modulo-items > .card:hover .module-name {
  color: white;
}

.iconFav {
  visibility: hidden;
  color: #358d30;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  top: 10%;
  left: 90%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.iconFav.active {
  color: #358d30;
  visibility: visible;
}

.module-align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.module-name {
  font-size: 18px;
  color: #3c3c3b;
}

.hello-message {
  text-align: center;
  height: 50px;
  line-height: 50px;
  padding-top: 0px;
  width: 100%;
  margin: 0;
  background: #f5f5f5;
  color: #3c3c3b;
  font-weight: 400;
  font-size: 20px;
}

.hello-message span {
  color: #74b959;
}

.flex-align {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

@media only screen and (max-width: 420px) {
  .hello-message {
    height: 100px;
    padding: 0 12px;
  }

  .modulo-items > .card {
    margin-bottom: 15px;
    width: 100% !important;
  }

  .flex-align {
    justify-content: center;
  }

  .iconFav {
    visibility: visible;
  }
}

@media only screen and (min-width: 768px) {
  .hello-message {
    height: 50px;
    padding: 0 12px;
  }

  .modulo-items > .card {
    margin-bottom: 15px;
  }
}
